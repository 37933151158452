import { NextOrObserver, onAuthStateChanged, User } from 'firebase/auth';
import React, { useState } from 'react';
import { firebaseAuth } from '../setup/firebase';

/**
 * Authentication/user details
 */
export type IAuth = Pick<User, 'displayName' | 'email' | 'photoURL' | 'uid'>;

export const emptyAuth: IAuth = {
  uid: '',
  displayName: '',
  email: '',
  photoURL: '',
};

/**
 * state of auth, undefined is not initialised, null is not logged in
 */
export type IAuthState = IAuth | null | undefined;

/**
 * Listen to auth change
 * @returns
 */
export default function useAuth(): IAuthState {
  // console.debug('useAuth');

  const [state, setState] = useState<IAuthState>();

  // action when changes happen to auth state
  const onChange: NextOrObserver<User> = (user: User | null) => {
    // console.debug('useAuth.onChange');
    setState(user);
  };

  // listener for auth changes
  React.useEffect(() => {
    // console.debug('useAuth.useEffect');
    // listen for auth state changes
    const unsubscribe = onAuthStateChanged(firebaseAuth, onChange);
    // unsubscribe to the listener when unmounting
    return () => {
      // console.debug('useAuth.unsubscribe');
      unsubscribe();
    };
  }, []);

  return state;
}
